<template>

  <indexComponent />
</template>

<script>
  import indexComponent from './components/indexComponent.vue'

  export default {
    name: 'App',
    components: {
      indexComponent
    },

    mounted() {
      // 禁止复制
      document.addEventListener('copy', (event) => {
        event.preventDefault(); // 阻止默认复制行为
      });


      // 禁止F12
      document.addEventListener('keydown', function (e) {
        if (e.key === 'F12') {
          e.preventDefault(); // 阻止默认行为
          return false; // 阻止进一步的处理
        }
      });

      // 禁止右键菜单
      document.addEventListener('contextmenu', function (e) {
        e.preventDefault();
        return false;
      });

    }
  }


  // window.onload = function () {
  //   document.addEventListener('touchstart', function (e) {
  //     console.log("1", e.touches.length)
  //     if (e.touches.length > 1) {
  //       e.preventDefault()
  //     }
  //   })
  //   document.addEventListener('gesturestart', function (e) {
  //     console.log("2")
  //     e.preventDefault()
  //   })
  // }
</script>

<style>

</style>