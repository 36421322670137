<template>
  <div class="home">
    <video class="home-video" autoplay muted loop src="@/assets/home-video.mp4">

    </video>

    <div class="home-content">
      <div class="login-title" @click="goToLogin"
        style="display: flex;align-items: center;position: absolute; right: 8%; top: 8%;font-weight: 700;font-size: 18px;">
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAH6SURBVHgB7dyBTcJAFMbxh3EARugIjqCTUCcQJhAmUCcQJ8ENcAO6gWxwvpecIYr0Gr+DewffL7lUBY35p7TXoypCRERERERERxBCGOt41fEZh33cCA2jsdZh30ooTUPdhsNuBHQl56/peYwBS2NAEAOCGBDEgCAGBDEgiAFBDAhiQBADghgQdC3OxHU6G91oNOrEOVd7oC106majw9bqNvr5kzjnJqDGmuqm/fXlaYzqlqc9cHLg663niJ4CjnsecxvRU8C3xOMuI3oK+KzjI/EcdxHdBNQpy1Y3d1JZRFfTmBojursSqS1ilisRe+dfNw+yP487Noto0e+lkFyXcnbF0EoZFnGrEWdSAPwSju/ut1LWNL4KTi7HMRB+dz+TRgrIEfBdfOikADhgXHJ6kbIW8ex9cllOIvrL2zHIph0TyceOaUMODxZvLrRji6o6NiFtPuBntT3f3wrI3UQ6rkjbgmqTeKqLPc/binQjFcUznlakG6ksnvG0Bz5KZfGMp4CpM67Ls62ngH3zOLdTlRqW9F3P8zytSC91s5DdJZntkTNOkv8hZPwrooubSJsabun4xpuLQAwIYkAQA4IYEMSAIAYEMSCIAUEMCGJAEAOCGBB0CQH77jPshNJ03W/1x1rgWmiYeKfDMvz8109FbocjIiIiIiLCfQHBnVkbovxNIAAAAABJRU5ErkJggg=="
          class="ant-image-img header-image-logo" style="width: 28px;height: 28px;margin-right: 3px;" />
        用户登录
      </div>

      <div>
        <h1 class="content-title">AURORA-极光</h1>
        <div class="content-introduce-text">
          <h1 class="content-introduce-1">快如闪电</h1>
          <h1 class="content-introduce-2">连接世界</h1>
        </div>

        <div class="content-download">

          <a href="https://a003005.jsa1004.com/download/aurora.apk">
            <img class="img-bg img-bg-android" src="@/assets/android-342c5f80.svg" />
            <img class="img-bg img-bg-android-mobile" src="@/assets/android-7facdfae.svg" />
          </a>
          <a href="https://a003005.jsa1004.com/download/aurora.msi">
            <img class="img-bg img-bg-windows" src="@/assets/windows-8cc347d3.svg" />
            <img class="img-bg img-bg-windows-mobile" src="@/assets/windows-bc1852bb.svg" />
          </a>
          <a href="https://a003005.jsa1004.com/download/aurora.dmg">
            <img class="img-bg img-bg-mac" src="@/assets/mac-f79dac12.svg" />
            <img class="img-bg img-bg-mac-mobile" src="@/assets/mac-39a7e9c1.svg" />
          </a>
          <a href="#" @click="showIosModal()">
            <img class="img-bg img-bg-appstore" src="@/assets/appstore-9ab4ce29.svg" />
            <img class="img-bg img-bg-appstore-mobile" src="@/assets/appstore-efeaa3ac.svg" />
          </a>
          <!-- <a href="#">
            <img class="img-bg img-bg-testflight" src="@/assets/testflight-0b34cd32.svg" />
            <img class="img-bg img-bg-testflight-mobile" src="@/assets/testflight-257ad22f.svg" />
          </a> -->
        </div>

      </div>
      <div class="content-bottom">
        <div class="concat-wrap">
          <!-- <div class="item">
            <img class="icon" src="@/assets/mail-9f5862d7.svg" style="width: 2vh; height: 2vh;"><span
              class="icon icon-label">support@lightning-vpn.com</span>
          </div> -->
          <a class="item" href="#"><img class="icon" src="@/assets/help-a134d98d.svg"
              style="width: 2vh; height: 2vh;"><span class="icon icon-label">安装客户端可咨询客服</span></a>
          <!-- <a class="item"
            href="https://t.me/palantirgroup"><img class="icon" src="@/assets/telegram-3a20213d.svg"
              style="width: 2vh; height: 2vh;"><span class="icon icon-label">Telegram</span></a><a class="item"
            href="https://twitter.com/xiaodiqiuyi"><img class="icon" src="@/assets/twitter-8cc35512.svg"
              style="width: 2vh; height: 2vh;"><span class="icon icon-label">Twitter</span></a> -->
        </div>

        <span class="content-copyright">Copyright © AURORA-极光 Inc. All rights reserved.</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { Modal } from 'ant-design-vue';
  import { h } from 'vue';
  export default {
    name: "indexComponent",
    mounted() {
      this.initMsg()
    },
    methods: {

      goToLogin() {
        window.location.href = 'https://sga.jmvks.cn/#/login'; // 正确的跳转方式
      },

      initMsg() {
        Modal.info({
          // centered: true,
          closable: true,
          title: '为提供更稳定更高效的服务，本站已进行全面升级。',
          content: h('div', {}, [
            h('p', {
              style: {
                fontSize: '14px', // 调整字体大小
                fontWeight: '500', // 调整字体粗细
                color: '#333', // 调整字体颜色
                lineHeight: '1.5' // 调整行高，增加可读性
              }
            }, ''),
            h('p', {
              style: {
                fontSize: '14px', // 调整字体大小
                fontWeight: '500', // 调整字体粗细
                color: '#333', // 调整字体颜色
                lineHeight: '1.5' // 调整行高，增加可读性
              }
            }, '所有购买请在客户端内进行，苹果手机IOS点击右上角登入购买并查看教程。'),
            h('p', {
              style: {
                fontSize: '14px', // 调整字体大小
                fontWeight: '500', // 调整字体粗细
                color: '#333', // 调整字体颜色
                lineHeight: '1.5' // 调整行高，增加可读性
              }
            }, '请及时下载更换新客户端使用，老客户端将于2025年12月20号停止使用。'),
            h('p', {
              style: {
                fontSize: '14px', // 调整字体大小
                fontWeight: '500', // 调整字体粗细
                color: '#333', // 调整字体颜色
                lineHeight: '1.5' // 调整行高，增加可读性
              }
            }, '原有用户数据已等价同步至新客户端内，使用原邮箱与密码登入即可使用。'),
            h('p', {
              style: {
                fontSize: '14px', // 调整字体大小
                fontWeight: '500', // 调整字体粗细
                color: '#333', // 调整字体颜色
                lineHeight: '1.5' // 调整行高，增加可读性
              }
            }, 'IOS用户暂时无法在客户端内购买与续费，SSTAP用户可直接使用新客户端开启TUN代理。'),



          ]),
          okText: '确认',
          width: 550,
          cancelText: '取消',
          afterClose() {

          },
        });

      },

      showIosModal() {
        Modal.info({
          closable: true,
          title: '安装提示',
          content: h('div', {}, [
            h('p', {
              style: {
                fontSize: '14px', // 调整字体大小
                fontWeight: '500', // 调整字体粗细
                color: '#333', // 调整字体颜色
                lineHeight: '1.5' // 调整行高，增加可读性
              }
            }, ''),
            h('p', {
              style: {
                fontSize: '14px', // 调整字体大小
                fontWeight: '500', // 调整字体粗细
                color: '#333', // 调整字体颜色
                lineHeight: '1.5' // 调整行高，增加可读性
              }
            }, '苹果手机IOS用户请打开此链接质询客服下载方式：https://bison.aging-trn.xyz/'),






          ]),
          okText: '确认',
          width: 550,
          cancelText: '取消',
          afterClose() {

          },
        });

      },
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .home {
    width: 100%;
    height: 100%;
  }

  .home-bgimg {
    position: fixed;
    top: 0;
  }

  .home-video {
    width: auto;
    height: auto;
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -999;
  }

  .home-content {
    color: rgb(255, 255, 255);
    margin-left: 12vw;
    /* margin-top: 5vh; */
    margin-right: 12vw;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .home-content a:hover {
    opacity: 0.8;
  }

  .img-bg {
    background: rgba(238, 238, 238, .5);
    margin-right: 12px;
    border-radius: 8px;
    cursor: pointer;
  }

  .content-title {
    font-weight: 600;
    font-family: Roboto, sans-serif;
    font-size: 40px;
    line-height: 78px;
    margin-top: 20vh;
  }

  .content-introduce-1,
  .content-introduce-2 {
    margin: 0px 0px 50px;
    font-weight: 700;
    font-family: Roboto, sans-serif;
    font-size: 70px;
    line-height: 78px;
  }

  .content-copyright {
    color: #d7d7d7;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 400 !important;
  }

  .content-bottom {
    height: 19%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  }

  .concat-wrap {
    display: flex;
    flex-direction: row;
  }

  .concat-wrap div,
  .concat-wrap a {
    display: flex;
    justify-content: flex-start;
    text-decoration: none;
    color: #fff;
    padding-right: 12px;
    align-items: center;
  }

  .icon-label {
    margin-left: 6px;
    font-size: 0.875rem;
    font-weight: 400;
    /* font-family: Roboto, sans-serif; */
    line-height: 2vh;
  }

  .content-introduce-1 {
    margin-right: 60px;
  }

  .content-introduce-text {
    display: flex;
    flex-direction: row;
  }



  .img-bg-appstore-mobile,
  .img-bg-android-mobile,
  .img-bg-mac-mobile,
  .img-bg-windows-mobile,
  .img-bg-testflight-mobile {
    display: none;
  }




  @media(max-width:900px) {
    .content-introduce-2 {
      margin: 0px 0px 100px;
    }

    .login-title {
      top: 5% !important;
      right: 5% !important;
    }

    .home-video {
      bottom: 9999px;
    }

    .home {
      background: url(@/assets/home-bg-img.png) no-repeat;
      background-size: 100% 100%;
      padding: 24px 24px 0px 24px;
      width: 100vw;
      height: 100vh;
      box-sizing: border-box;
      /* 确保内边距不会增加元素的总宽高 */
      overflow: hidden;
      /* 防止出现滚动条 */
    }


    .home-content {
      margin: 0;
    }

    .content-title {
      margin-top: 10vh;
      font-size: 24px;
      font-family: 600;
    }

    .content-introduce-text {
      flex-direction: column;
    }

    .content-introduce-1 {
      margin: 0;
    }

    .img-bg-appstore,
    .img-bg-android,
    .img-bg-mac,
    .img-bg-windows,
    .img-bg-testflight {
      display: none;
    }

    .img-bg-appstore-mobile,
    .img-bg-android-mobile,
    .img-bg-mac-mobile,
    .img-bg-windows-mobile,
    .img-bg-testflight-mobile {
      display: block;
    }

    .content-download {
      display: flex;
      flex-wrap: wrap;
    }

    .content-download a {
      margin-bottom: 5px;
    }

    .concat-wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .concat-wrap .item {
      margin-top: 10px;
    }

    .content-copyright {
      font-size: 12px;
    }
  }
</style>